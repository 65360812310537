import { Button, Skeleton, Switch } from '@mui/material';
import {
  PasswordProtectionUser,
  useDeleteSiteProtectionUser,
  useGetPasswordProtectionStatus,
  usePasswordProtectionUsers,
  useSetSiteProtection,
} from 'api/security';
import { Table } from 'component/new_design/base/Table';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { Trans, useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import LockClose from 'component/new_design/icons/LockClose.svg?react';
import Plus from 'component/new_design/icons/Plus.svg?react';
import Delete from 'component/new_design/icons/Delete.svg?react';
import { useState } from 'react';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { AddPasswordProtectionUserDialog } from '../../SiteDetails/Security/AddPasswordProtectionUserDialog';
import { LoadingSwitch } from 'component/new_design/base/LoadingSwitch';

interface SecurityTableProps {
  readonly siteId: string;
}

export const SecurityTable = ({ siteId }: SecurityTableProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tableState = usePaginatedListState();

  const { data, isLoading, refetch } = usePasswordProtectionUsers(siteId, tableState);
  const removeUser = useDeleteSiteProtectionUser({ siteId });
  const setSiteProtection = useSetSiteProtection({ siteId });
  const passwordProtection = useGetPasswordProtectionStatus({ siteId });
  const passwordProtectionValue = passwordProtection.data?.data.result?.enabled ?? false;

  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);

  const columns: TableColumnType<PasswordProtectionUser>[] = [
    {
      label: t('username'),
      key: 'username',
    },
  ];

  const rowActions: TableRowActionType<PasswordProtectionUser>[] = [
    {
      icon: <Delete />,
      label: t('remove'),
      onClick: row => setIdToDelete(row.user_id!),
      color: 'reds.500',
    },
  ];

  const handleDeleteConfirmed = async () => {
    if (!idToDelete) {
      enqueueSnackbar(null, { variant: 'error' });
      return;
    }

    await removeUser.mutateAsync(idToDelete);
    await refetch();
    enqueueSnackbar(t('user_removed_successfully'), { variant: 'success' });
  };

  const userNameToDelete =
    idToDelete && data?.data.result?.users?.find(user => user.user_id === idToDelete)?.username;

  const handleProtectionSwitchChange = async (value: boolean) => {
    await setSiteProtection.mutateAsync(value);
    if (value) {
      enqueueSnackbar(t('password_protection_was_enabled_successfully'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('password_protection_was_disabled_successfully'), { variant: 'success' });
    }
  };

  return (
    <>
      <Table
        headerComponent={
          <LoadingSwitch
            checked={passwordProtectionValue}
            onChange={handleProtectionSwitchChange}
          />
        }
        title={t('password_protection')}
        label={t('password_protection')}
        description={t('password_protection_table_description')}
        isLoading={isLoading}
        data={data?.data.result?.users ?? []}
        totalRowCount={data?.data.metadata?.total ?? 0}
        state={tableState}
        emptyState={
          <EmptyState
            icon={<LockClose />}
            title={t('no_data_yet')}
            description={t('password_protection_table_no_data')}
            action={
              <Button
                startIcon={<Plus />}
                variant="contained"
                onClick={() => setIsAddUserDialogOpen(true)}
              >
                {t('invite_user')}
              </Button>
            }
          />
        }
        columns={columns}
        rowActions={rowActions}
        enableSearch
        enablePagination
      >
        <Button
          startIcon={<Plus />}
          variant="contained"
          onClick={() => setIsAddUserDialogOpen(true)}
        >
          {t('invite_user')}
        </Button>
      </Table>
      {idToDelete ? (
        <ConfirmationDialog
          title={t('remove_user')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_remove"
              values={{ name: userNameToDelete }}
              components={[<strong key={0} />]}
            />
          }
          onConfirm={handleDeleteConfirmed}
          onClose={() => setIdToDelete(null)}
          confirmColor="error"
          confirmText={t('remove')}
        />
      ) : null}
      {isAddUserDialogOpen ? (
        <AddPasswordProtectionUserDialog
          siteId={siteId}
          onSuccess={refetch}
          onClose={() => setIsAddUserDialogOpen(false)}
        />
      ) : null}
    </>
  );
};
