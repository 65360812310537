import { SecurityTable } from 'component/new_design/partial/tables/security/SecurityTable';

interface SecurityProps {
  readonly siteId: string;
}

const Security = ({ siteId }: SecurityProps) => {
  return <SecurityTable siteId={siteId} />;
};

export default Security;
